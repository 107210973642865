import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import loadable from '@loadable/component';
import ResourceTypes from 'constants/ResourceTypes';
const HomePage = loadable(() => import('./home/HomePage'));
const SettingsRoutes = loadable(() => import('./settings/SettingsRoutes'));
const DataCategoriesRoutes = loadable(() => import('./datacategories/DataCategoriesRoutes'));
const SystemsRoutes = loadable(() => import('./systems/SystemsRoutes'));
const PhysicalEntitiesRoutes = loadable(() =>
  import('pages/systems/physical_entities/PhysicalEntitiesRoutes')
);
const RoutinesRoutes = loadable(() => import('./systems/routines/RoutinesRoutes'));
const DataFlowsRoutes = loadable(() => import('./dataflows/DataFlowsRoutes'));
const ProcessingsRoutes = loadable(() => import('./processings/ProcessingsRoutes'));
const TasksRoutes = loadable(() => import('./tasks/TasksRoutes'));
const DataSubjectsRoutes = loadable(() => import('./datasubjects/DataSubjectsRoutes'));
const DataActorsRoutes = loadable(() => import('./data_actors/DataActorsRoutes'));
const TaskGrantsRoutes = loadable(() => import('./taskgrants/TaskGrantsRoutes'));
const GraphPage = loadable(() => import('./graph/GraphPage'));
const ConsentsPage = loadable(() => import('./consents/ConsentsRoutes'));
const ContractsRoutes = loadable(() => import('./contracts/ContractsRoutes'));
const ReportsRoutes = loadable(() => import('./reports/ReportsRoutes'));
const HistoryRoutes = loadable(() => import('./history/HistoryRoutes'));
const WorkflowsRoutes = loadable(() => import('./workflows/WorkflowsRoutes'));
const TemplatesRoutes = loadable(() => import('./templates/TemplatesRoutes'));
const LogicalFieldRoutes = loadable(() => import('./logicalfields/LogicalFieldsRoutes'));
const SocialRoutes = loadable(() => import('./social/SocialRoutes'));
const ClassificationRoutes = loadable(() => import('./classification/ClassificationRoutes'));
const IssueMngtRoutes = loadable(() => import('./issueMngt/IssueMngtRoutes'));
const DataQualityRoutes = loadable(() => import('./quality/DataQualityRoutes'));
const StewardshipRoutes = loadable(() => import('./stewardship/StewardshipRoutes'));
const EngagementDashboardsRoutes = loadable(() =>
  import('./engagement/EngagementDashboardsRoutes')
);
const PhysicalConstraintsRoutes = loadable(() =>
  import('./systems/physical_constraints/PhysicalConstraintsRoutes')
);
const DataProductsRoutes = loadable(() => import('./systems/dataproducts/DataProductsRoutes'));

const Routes = (props) => (
  <ScrollToTop>
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route path='/datacategories' component={DataCategoriesRoutes} />
      <Route path='/tasks' component={TasksRoutes} />
      <Route path='/processings' component={ProcessingsRoutes} />
      <Route path='/data_actors' component={DataActorsRoutes} />
      <Route path='/workflows' component={WorkflowsRoutes} />
      <Route path='/datasubjects' component={DataSubjectsRoutes} />
      <Route path='/consents' component={ConsentsPage} />
      <Route path='/contracts' component={ContractsRoutes} />
      <Route path='/systems' component={SystemsRoutes} />
      <Route path='/physicalentities' component={PhysicalEntitiesRoutes} />
      <Route path='/dataflows' component={DataFlowsRoutes} />
      <Route path='/taskgrants' component={TaskGrantsRoutes} />
      <Route path='/graph' component={GraphPage} />
      <Route path='/settings' component={SettingsRoutes} />
      <Route path='/reports' component={ReportsRoutes} />
      <Route path='/history' component={HistoryRoutes} />
      <Route path='/templates' component={TemplatesRoutes} />
      <Route path='/logicalfields' component={LogicalFieldRoutes} />
      <Route path='/social' component={SocialRoutes} />
      <Route path='/classification' component={ClassificationRoutes} />
      <Route path='/issue-management' component={IssueMngtRoutes} />
      <Route path='/data-quality' component={DataQualityRoutes} />
      <Route path='/stewardship' component={StewardshipRoutes} />
      <Route path='/engagement' component={EngagementDashboardsRoutes} />
      <Route path='/routines' component={RoutinesRoutes} />
      <Route path='/physical-constraints' component={PhysicalConstraintsRoutes} />
      <Route path='/dataproducts' component={DataProductsRoutes} />
      <Redirect to='/'></Redirect>
    </Switch>
  </ScrollToTop>
);

function ScrollToTop({ children }) {
  let history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (!location?.state?.keepScroll) {
        window.scrollTo(0, 0);
        let scrollNode = document.getElementById('material-title-root')?.parentNode;
        if (scrollNode) {
          scrollNode.scrollTop = 0;
          scrollNode.scrollLeft = 0;
        }
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

export function createUrl(resourceType) {
  switch (resourceType) {
    case 'PROCESSING':
      return `/processings`;
    case 'DATA_CATEGORY':
      return `/datacategories`;
    case 'LOGICAL_FIELD':
      return `/logicalfields`;
    case 'TASK':
      return `/tasks`;
    case 'DATA_ACTOR':
      return `/data_actors`;
    case 'PHYSICAL_ENTITY':
      return `/physicalentities`;
    case 'SYSTEM':
      return `/systems`;
    case 'PHYSICAL_FIELD':
      return `/systems/physical_fields`;
    case 'QUALITY_SUITES': //wrong mapping in notification type do not remove up to may 2026
    case 'QUALITY_SUITE':
      return `/data-quality/kqis/suites`;
    case 'QUALITY_CHECK':
      return '/data-quality/kqis/checks';
    case 'QUALITY_RESULT':
      return '/data-quality/kqis/results';
    case 'QUALITY_PROBE_DEFINITION':
      return '/data-quality/probes/definitions';
    case 'QUALITY_PROBE_PROJECT':
      return '/data-quality/probes/projects';
    case 'PROPOSAL':
      return `/workflows/proposals`;
    case 'ISSUE':
      return `/issue-management/issues`;
    case 'CAMPAIGN':
      return `/issue-management/campaigns`;
    case 'REL_DATA_FLOW':
      return `/dataflows/detail`;
    case 'QUERY_BUCKET':
      return `/dataflows/queryparser/buckets`;
    case ResourceTypes.DATA_PRODUCT:
      return `/dataproducts/detail`;
    case ResourceTypes.DATA_PRODUCT_PORT:
      return `/dataproducts/detail/*/ports`;
    case ResourceTypes.DATA_PRODUCT_DOMAIN:
      return `/dataproducts/domains/detail`;
    case ResourceTypes.PROFILING_MONITOR:
      return '/data-quality/data-observability/monitors';
    case ResourceTypes.PROFILING_METRIC_INCIDENT:
      return '/data-quality/data-observability/metric-incidents';
    case ResourceTypes.GOVERNANCE_POLICY:
      return '/dataproducts/governance-policies/policies/detail';
    case ResourceTypes.GOVERNANCE_POLICY_SUITE:
      return '/dataproducts/governance-policies/suites/detail';
    default:
      return null;
  }
}

export function createUrlItem(resourceType, resourceIdentifier) {
  let baseUrl = createUrl(resourceType);
  return baseUrl ? `${baseUrl}/${resourceIdentifier}` : null;
}

export default Routes;
